<template>
  <div class="mt-4" id="contendData">
    <div class="flex flex-wrap items-end gap-4 mb-3">
      <label class="block w-full md:w-auto">
        <span
          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
        >
          Tipo de movimiento
        </span>
        <select
          class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full"
          v-model="dataForm.typeFilter"
        >
          <option value="SALIDA">SALIDAS</option>
          <option value="DEVOLUCION">DEVOLUCION</option>
          <option value="AJUSTE">AJUSTE</option>
        </select>
      </label>
      <label class="block w-full md:w-auto">
        <span
          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
        >
          Fec. de inicio
        </span>
        <input
          type="date"
          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
          v-model="dataForm.dateStart"
          @keydown.enter.prevent
        />
      </label>
      <label class="block w-full md:w-auto">
        <span
          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
        >
          Fec. término
        </span>
        <input
          type="date"
          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
          v-model="dataForm.dateEnd"
          @keydown.enter.prevent
        />
      </label>
      <button
        class="bg-blue-50 text-blue-500 px-3 py-2 border-2 border-blue-50 hover:border-blue-500 hover:bg-blue-500 hover:text-white text-sm rounded-md font-semibold"
        @click="resetValuesAndGetData"
      >
        Consultar
      </button>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
          <option value="10000">10000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por usuario, producto o fecha de movimiento..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
              autocomplete="off"
            />
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
                    1: Para poner una tabla responsiva añadir lo siguiente:
                      1.1: class="resize-generate"
                      1.2: ref="widthNav" 
                      1.3: @resize="handleResize"
                    -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
        id="tableContend"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="8" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.nombres }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.nombre_producto }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.tipo_de_movimiento }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.concepto }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ format(register.cantidad) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ format(register.stock_anterior) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ format(register.stock_actual) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-right"
                >
                  {{ formatDateTime(register.fecha_de_movimiento) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "@/api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import { errorActions, warningActions } from "@/alerts";

import { money, format } from "@/publicjs/money";
import { formatDateTime } from "@/publicjs/formatDate";
import { idLocalStore } from "@/publicjs/localStorage";
import { observeElement } from "@/observer";
import { firstDaytAndLastDay } from "@/publicjs/getFirstAndLastDayOfMount";

export default {
  name: "MainProductMovement",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "usuario",
        },
        {
          title: "productos",
        },
        {
          title: "Tipo",
        },
        {
          title: "concepto",
        },
        {
          title: "cantidad",
        },
        {
          title: "stock anterior",
        },
        {
          title: "stock actual",
        },
        {
          title: "fecha",
        },
      ],
    ];

    const dataForm = ref({
      idStore: 0,
      dateEnd: null,
      dateStart: null,
      typeFilter: "SALIDA",
    });

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const getData = async () => {
      if (
        dataForm.value.dateEnd === null ||
        dataForm.value.dateEnd === "" ||
        dataForm.value.dateStart === null ||
        dataForm.value.dateStart === ""
      ) {
        errorActions(
          "Por favor ingresa el rango de fechas para porder realizar el reporte"
        );
        return;
      }
      loading.value = true;
      const response = await axios
        .get(
          `movimiento-de-productos/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}&idstore=${dataForm.value.idStore}&type=${dataForm.value.typeFilter}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data.data;

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if (data.length === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      registers.value = data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      observeElement("#contendData");
      observeElement("#tableContend");
      dataForm.value.idStore = await idLocalStore();
      
      const dates = await firstDaytAndLastDay();
      dataForm.value.dateEnd = dates.lastDay
      dataForm.value.dateStart = dates.firstDay
      loading.value = false;
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      widthNav,
      handleResize,
      money,
      format,
      formatDateTime,
      dataForm,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
